import { Calificacion } from '@/entidades/MaderaVerde/KtbDespacho';
import {Editar,Save,Obtener} from '@/Generico/Conexion';
import {axiosKtb} from '@/plugins/axios';

export class SubirImagenRequest
{
    id:number;
    imagen:string 

    constructor(_id:number,_imagen:string)
    {
        this.id = _id;
        this.imagen = _imagen;
    }
}

export class ConfirmarDefecto 
{
    defectoId: number;
    cantidad: number;
    largoId: number;

    constructor(_defectoId:number,_lardoId:number, _cantidad:number)
    {
        this.defectoId = _defectoId;
        this.cantidad = _cantidad;
        this.largoId = _lardoId;
    }
}

export class ConfirmarTablonModalDetalle 
{
    id: number;
    fechaTumba: string;
    volumen: number;
    espesorId: number;
    largoId:number;
    numPlantillas:number;
    ancho:number;
    largoDescripcion:string;
    constructor(_id:number,_fechaTumba:string,_volumen:number,_espesorId:number,_largoId:number,_numPlantillas:number,_ancho:number,largoDescripcion:string)
    {
        this.id = _id;
        this.fechaTumba = _fechaTumba;
        this.volumen = _volumen;
        this.espesorId = _espesorId;
        this.largoId = _largoId;
        this.numPlantillas = _numPlantillas;
        this.ancho = _ancho;
        this.largoDescripcion = largoDescripcion;
    }
}

export class ConfirmarTablonModal 
{
    procedencia?:string="";
    id?: number;
    plantaId?: number;
    volumen?: number;
    usuarioId?: string;
    porcentaje?: number;
    piezas?: number;
    pies?: number;
    piesRechazado?: number;
    largoId?: number;
    defectos?: ConfirmarDefecto[];
    items?: ConfirmarTablonModalDetalle[];
    calificacion!:Calificacion;
    constructor(_id?:number,_plantaId?:number,_usuarioId?:string,_largoId?:number
        ,_porcentaje?:number,_piezas?:number,_pies?:number,_piesRechazado?:number,
        _volumen?:number,
        _defectos?:Array<ConfirmarDefecto>,
        _items?:Array<ConfirmarTablonModalDetalle>,
        _procedencia?:string,
        _calificacionAplicada?:number
        )
        {
            this.procedencia = _procedencia;
            this.id = _id;
            this.plantaId = _plantaId;
            this.usuarioId = _usuarioId;
            this.largoId =   _largoId;
            this.porcentaje= _porcentaje;
            this.piezas = _piezas;
            this.pies = _pies;
            this.piesRechazado = _piesRechazado;
            this.volumen = _volumen;
            this.defectos = _defectos == undefined ? new Array<ConfirmarDefecto>() : _defectos;
            this.items = _items == undefined ? new Array<ConfirmarTablonModalDetalle>() : _items;
        }
}


export async function GuardarTablon(confirmar:ConfirmarTablonModal)
{
    return Editar<ConfirmarTablonModal>('recepcion/confirmartablon',confirmar,axiosKtb);
}

export async function SubirImagen(confirmar:SubirImagenRequest)
{
    return Save<SubirImagenRequest>('recepcion/subirIMagen',confirmar,axiosKtb);
}
export async function ObtenerImagenKtb(id:number)
{
    return Obtener<SubirImagenRequest>(`recepcion/obtenerImagen/${id}`,axiosKtb);
}
export async function SendCalidad(confirmar:ConfirmarTablonModal)
{
    return Save<ConfirmarTablonModal>('recepcion/SendCalidad',confirmar,axiosKtb);
}


